//$(document).ready(function(){
//});

//document.addEventListener('DOMContentLoaded', function() {
document.addEventListener('turbolinks:load', function() {
  let index = 0;
  let screens = [
      {width:0, column:2, offset:0},
      {width:575, column:3, offset:1},
      {width:767, column:4, offset:2},
      {width:991, column:6, offset:4},
  ];
  let column = 0;
  let offset = 0;
  for (var i=0; i<screens.length; i++) {
    if (screens[i].width < window.innerWidth) {
      column = screens[i].column;
      offset = screens[i].offset;
    }
  }

  // https://www.bootply.com/132400
  jQuery('.carousel[data-type="multi"] .carousel-item').each(function(){
    if (index != 0 && index % column != 0) {
      this.remove();
    }

    var next = jQuery(this).next();
    if (!next.length) {
      next = jQuery(this).siblings(':first');
    }
    next.children(':first-child').clone().appendTo(jQuery(this));
    
    for (var i=0; i<offset; i++) {
      next = next.next();
      if (!next.length) {
      	next = jQuery(this).siblings(':first');
      }
      
      next.children(':first-child').clone().appendTo(jQuery(this));
    }
    index += 1;
  });

  $('.carousel').carousel({ interval: 5000 });

  jQuery('.close-toast').click(function(){
    $('.toast').hide();
  });
});